import { MobilitySearchProps, MobilityCarProps, Store } from '@/types/mobility';
import { LowestCarsGroupsProps } from '@/types/mobility/search';
import { ActionMap } from '@/utils/contexts';
export interface FiltersProps {
  page: number;
  prices: {
    open: boolean;
    value: {
      min: number;
      max: number;
    };
  };
  pricesMinMax: {
    value: {
      totalMin: number;
      totalMax: number;
    };
  };
  categories: {
    value: string[] | null;
  };
  ar_conditional: {
    value: boolean;
  };
  tariff_type: {
    value: boolean;
  };
  payment_method: {
    open: boolean;
    value: string[] | null;
  };
  transmission: {
    open: boolean;
    value: string[] | null;
  };
  fuel: {
    open: boolean;
    value: string[] | null;
  };
  companies: {
    open: boolean;
    value: string[] | null;
  };
  passengers: {
    open: boolean;
    value: number[] | null;
  };
  doors: {
    open: boolean;
    value: number[] | null;
  };
  baggages: {
    open: boolean;
    value: number[] | null;
  };
  protection: {
    open: boolean;
    value: string[] | null;
  };
  km: {
    open: boolean;
    value: string[] | null;
  };
  experience: {
    open: boolean;
    value: string[] | null;
  };
}
export type FiltersType = keyof FiltersProps;

export type SearchLocationFields = {
  city: string;
  iata: string;
  type: string;
  datetime: string;
  latitude: string;
  longitude: string;
};
export interface SearchProps {
  pickup: SearchLocationFields;
  return: SearchLocationFields;
}

interface TotalValueInformationProps {
  HighestTotalValue: number;
  HighestEquivalentTotalValue: number;
  LowestTotalValue: number;
  LowestEquivalentTotalValue: number;
}
export interface StateProps {
  googleMapsLoaded: boolean;
  loading: boolean;
  search: SearchProps;
  filters: FiltersProps;
  lowestCarsGroups: LowestCarsGroupsProps[];
  totalValues: TotalValueInformationProps;
  sidebarOpen: boolean;
  originalData: MobilitySearchProps;
  fullList: MobilityCarProps[];
  stores: Store[];
  AvailabilityCarsGroups: any[];
  numberOfDailies: number;
  results: {
    quantity: number;
    pages: number;
    data: MobilityCarProps[];
    stores: Store[];
  };
  checkoutError: boolean;
  selectedCar: MobilityCarProps | null;
  selectCarApiLoading: boolean;
  visitedCars: MobilityCarProps[];
  tokens: {
    authorization: string;
    token: string;
  };
}

export enum ActionTypes {
  Loading = 'LOADING',
  SearchLoading = 'SEARCH_LOADING',
  Search = 'UPDATE_SEARCH',
  Filters = 'UPDATE_FILTERS',
  Collapse = 'UPDATE_COLLAPSE',
  SelectCar = 'SELECT_CAR',
  SelectCarApiLoading = 'SELECT_CAR_API_LOADING',
  SidebarOpen = 'SIDEBAR_OPEN',
  CheckoutError = 'CHECKOUT_ERROR',
  GoogleMaps = 'GOOGLE_MAPS_LOADED',
}
export type ActionPayload = {
  [ActionTypes.GoogleMaps]: {
    loaded: boolean;
  };
  [ActionTypes.Loading]: {
    loading: boolean;
  };
  [ActionTypes.SearchLoading]: {
    search: SearchProps;
    loading: boolean;
  };
  [ActionTypes.Search]: {
    loading: boolean;
    originalData: MobilitySearchProps;
    fullList: MobilityCarProps[];
    lowestCarsGroups: LowestCarsGroupsProps[];
    AvailabilityCarsGroups: any[];
    tokens: {
      authorization: string;
      token: string;
    };
  };
  [ActionTypes.Collapse]: {
    filters: FiltersProps;
  };
  [ActionTypes.Filters]: {
    filters: FiltersProps;
  };
  [ActionTypes.SelectCar]: {
    car: MobilityCarProps | null;
  };
  [ActionTypes.SelectCarApiLoading]: {
    selectCarApiLoading: boolean;
  };
  [ActionTypes.SidebarOpen]: {
    sidebarOpen: boolean;
  };
  [ActionTypes.CheckoutError]: {
    checkoutError: boolean;
  };
};

export type ReducerActions =
  ActionMap<ActionPayload>[keyof ActionMap<ActionPayload>];

export interface ContextProps extends StateProps {
  setPage: (page: number) => void;
  toggleFilter: (field: ToggleFilterFields, value: string) => void;
  toggleFilterNumbers: (
    field: ToggleFilterNumbersFields,
    value: number,
  ) => void;
  setFilter: (field: FilterFields, filter: FilterFieldsValue) => void;
  setCollapseFilter: (field: CollapseFilters) => void;
  setSelectCar: (car: MobilityCarProps | null) => void;
  confirmCarAndGoToCheckout: (car: MobilityCarProps) => void;
  fetchSearch: (searchParams: string) => void;
  openSidebar: (open: boolean) => void;
  setLoading: (isLoading: boolean) => void;
  removeAllFilters: () => void;
  loadGoogleMaps: () => void;
  setCheckoutError: (state: boolean) => void;
}

export type ToggleFilterFields = keyof Omit<
  FiltersProps,
  | 'page'
  | 'prices'
  | 'ar_conditional'
  | 'passengers'
  | 'doors'
  | 'baggages'
  | 'pricesMinMax'
  | 'tariff_type'
>;
export type ToggleFilterNumbersFields = keyof Pick<
  FiltersProps,
  'passengers' | 'doors' | 'baggages'
>;
export type FilterFields = keyof Pick<
  FiltersProps,
  'prices' | 'ar_conditional' | 'pricesMinMax' | 'tariff_type'
>;
export type FilterFieldsValue = Omit<
  Pick<
    FiltersProps,
    'prices' | 'ar_conditional' | 'pricesMinMax' | 'tariff_type'
  >[FilterFields],
  'open'
>;
export type CollapseFilters = keyof Omit<
  FiltersProps,
  'page' | 'categories' | 'ar_conditional' | 'pricesMinMax' | 'tariff_type'
>;
