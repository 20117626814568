'use client';

import React, {
  createContext,
  useMemo,
  useReducer,
  useCallback,
  useContext,
  useLayoutEffect,
  useEffect,
  useState,
} from 'react';
import { useRouter, useSearchParams } from 'next/navigation';

import { Tokens } from '@/config/tokens';
import { mobilitySearch } from '@/app/_actions/search';
import { selectCar } from '@/app/_actions/checkout/selectCar';
import { useAuth } from '@/contexts/AuthContext';
import { MobilitySearchProps, MobilityCarProps } from '@/types/mobility';
import {
  transformToUniqueCarsList,
  transformToStoreList,
  paginate,
  applyFilters,
  buildSearchData,
} from '@/utils/search';
import { removeItemFromArray } from '@/utils/array';
import { clearCheckoutData } from '@/app/_actions/checkout/clearData';

import {
  ActionTypes,
  StateProps,
  FiltersProps,
  ReducerActions,
  ContextProps,
  CollapseFilters,
  ToggleFilterNumbersFields,
  ToggleFilterFields,
  FilterFields,
  FilterFieldsValue,
  SearchProps,
} from './types';
import { useCurrency } from '../CurrencyContenxt';

export const filtersInitialState: StateProps = {
  googleMapsLoaded: false,
  loading: false,
  sidebarOpen: false,
  search: {} as SearchProps,
  filters: {
    page: 1,
    prices: {
      open: true,
      value: {
        min: 0,
        max: 0,
      },
    },
    pricesMinMax: {
      value: {
        totalMin: 0,
        totalMax: 0,
      },
    },
    categories: {
      value: null,
    },
    ar_conditional: {
      value: false,
    },
    payment_method: {
      open: true,
      value: ['Prepayment', 'LocalPayment'],
    },
    transmission: {
      open: true,
      value: null,
    },
    tariff_type: {
      value: false,
    },
    fuel: {
      open: true,
      value: null,
    },
    companies: {
      open: true,
      value: null,
    },
    passengers: {
      open: true,
      value: null,
    },
    doors: {
      open: true,
      value: null,
    },
    baggages: {
      open: true,
      value: null,
    },
    protection: {
      open: true,
      value: null,
    },
    km: {
      open: true,
      value: null,
      // value: ['free', 'limited'],
    },
    experience: {
      open: true,
      value: null,
    },
  } as FiltersProps,
  originalData: {} as MobilitySearchProps,
  checkoutError: false,
  fullList: [],
  stores: [],
  numberOfDailies: 0,
  AvailabilityCarsGroups: [],
  lowestCarsGroups: [],
  totalValues: {
    HighestTotalValue: 0,
    HighestEquivalentTotalValue: 0,
    LowestTotalValue: 0,
    LowestEquivalentTotalValue: 0,
  },
  results: {
    quantity: 0,
    pages: 1,
    data: [],
    stores: [],
  },
  selectedCar: null,
  selectCarApiLoading: false,
  visitedCars: [],
  tokens: {
    authorization: '',
    token: '',
  },
};

const PER_PAGE = 10;
const visitedCarsPopulate = (
  currentCars: MobilityCarProps[],
  newCar: MobilityCarProps | null,
) => {
  if (!newCar) return currentCars;

  const curr = currentCars.filter((c) => c.Index !== newCar.Index);
  curr.unshift(newCar);

  return curr;
};

const fetchReducer = (state: StateProps, action: ReducerActions) => {
  switch (action.type) {
    case ActionTypes.GoogleMaps:
      return { ...state, googleMapsLoaded: action.payload.loaded };
    case ActionTypes.Loading:
      return { ...state, loading: action.payload.loading };
    case ActionTypes.SearchLoading:
      return {
        ...state,
        loading: action.payload.loading,
        search: action.payload.search,
      };
    case ActionTypes.Filters:
      return {
        ...state,
        loading: false,
        filters: action.payload.filters,
      };
    case ActionTypes.Collapse:
      return {
        ...state,
        loading: false,
        filters: action.payload.filters,
      };
    case ActionTypes.SelectCar:
      return {
        ...state,
        selectedCar: action.payload.car,
        visitedCars: visitedCarsPopulate(state.visitedCars, action.payload.car),
      };
    case ActionTypes.SelectCarApiLoading:
      return {
        ...state,
        selectCarApiLoading: action.payload.selectCarApiLoading,
      };
    case ActionTypes.Search:
      return {
        ...state,
        loading: false,
        originalData: action.payload.originalData,
        fullList: action.payload.fullList,
        lowestCarsGroups: action.payload.lowestCarsGroups,
        AvailabilityCarsGroups: action.payload.AvailabilityCarsGroups,
        tokens: action.payload.tokens,
      };
    case ActionTypes.SidebarOpen:
      return {
        ...state,
        sidebarOpen: action.payload.sidebarOpen,
      };
    case ActionTypes.CheckoutError:
      return {
        ...state,
        checkoutError: action.payload.checkoutError,
      };
    default:
      return state;
  }
};

export const SearchContext = createContext<ContextProps>({} as ContextProps);

export const SearchProvider = ({
  children,
  data,
  tokens,
  login,
  password,
}: {
  children: React.ReactNode;
  data: MobilitySearchProps;
  tokens: {
    auth: string;
    token: string;
  };
  login: string;
  password: string;
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const [franchise, setFranchise] = useState(false);
  const { openLoginModal, isLogged } = useAuth();
  const { currency } = useCurrency();
  const fullList = transformToUniqueCarsList(data);
  const searchInitialData = buildSearchData(searchParams.toString());
  const AvailabilityCarsGroups = data?.Availabilities?.AvailabilityCarsGroups;
  const totalValues =
    data?.Availabilities?.TotalValueInformation ||
    filtersInitialState.totalValues;
  const numberOfDailies = data?.Availabilities?.NumberOfDaily;

  const syncFranchise = (resultList: MobilityCarProps[]) => {
    const withFranchise = resultList?.filter(
      (item) => item.CarWithFranchise,
    ).length;
    const withoutFranchise = resultList?.filter(
      (item) => !item.CarWithFranchise,
    ).length;

    return withFranchise > withoutFranchise;
  };

  const [state, dispatch] = useReducer<
    React.Reducer<StateProps, ReducerActions>
  >(fetchReducer, {
    ...filtersInitialState,
    search: searchInitialData,
    lowestCarsGroups: data?.Availabilities?.LowestCarsGroups,
    AvailabilityCarsGroups: data?.Availabilities?.AvailabilityCarsGroups,
    originalData: data,
    fullList,
    numberOfDailies,
    totalValues,
    tokens: {
      authorization: tokens?.auth,
      token: tokens?.token,
    },
  });

  const loadGoogleMaps = useCallback(async () => {
    const loadGoogleMapsScript = () => {
      return new Promise<void>((resolve, reject) => {
        const apiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
        const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&loading=async`;

        const alreadyLoaded =
          document.querySelectorAll(`script[src="${scriptSrc}"]`).length > 0;

        if (!alreadyLoaded) {
          const script = document.createElement('script');
          script.src = scriptSrc;
          script.id = 'google-maps';
          script.async = true;
          script.defer = true;
          document.head.appendChild(script);

          script.onload = () => {
            resolve();
          };

          script.onerror = () => {
            reject(new Error('Erro ao carregar a Google Maps API.'));
          };
        } else {
          dispatch({
            type: ActionTypes.GoogleMaps,
            payload: {
              loaded: true,
            },
          });
          resolve();
        }
      });
    };

    loadGoogleMapsScript()
      .then((cb) => {
        dispatch({
          type: ActionTypes.GoogleMaps,
          payload: {
            loaded: true,
          },
        });
        return cb;
      })
      .catch((error) => {
        console.log('erro ao carregar script', error.message);
        console.error(error);
      });
  }, []);

  // useEffect(() => {
  //   const checkedFranchise = syncFranchise(fullList);
  //   dispatch({
  //     type: ActionTypes.Filters,
  //     payload: {
  //       filters: {
  //         ...state.filters,
  //         tariff_type: {
  //           value: checkedFranchise,
  //         },
  //       },
  //     },
  //   });
  // }, []);

  const fetchSearch = useCallback(
    async (currentSearchParams: string) => {
      try {
        dispatch({
          type: ActionTypes.Loading,
          payload: {
            loading: true,
          },
        });

        const payload = buildSearchData(currentSearchParams);

        const data = await mobilitySearch({
          Login: login,
          Password: password,
          ...payload,
        });

        if (data && data.search && data.search.success) {
          const carList = transformToUniqueCarsList(data?.search);
          dispatch({
            type: ActionTypes.Search,
            payload: {
              loading: false,
              originalData: data?.search,
              fullList: carList,
              lowestCarsGroups: data?.search?.Availabilities?.LowestCarsGroups,
              AvailabilityCarsGroups:
                data?.search?.Availabilities?.AvailabilityCarsGroups,
              tokens: {
                authorization: data.authorizations || '',
                token: data.tokens || '',
              },
            },
          });

          dispatch({
            type: ActionTypes.SearchLoading,
            payload: {
              loading: false,
              search: payload,
            },
          });

          if (currency === 'BRL') {
            dispatch({
              type: ActionTypes.Filters,
              payload: {
                filters: {
                  ...state.filters,
                  tariff_type: {
                    value: syncFranchise(carList),
                  },
                  prices: {
                    ...state.filters.prices,
                    value: {
                      min: data.search.Availabilities?.TotalValueInformation
                        ?.LowestEquivalentTotalValue,
                      max: data.search.Availabilities?.TotalValueInformation
                        ?.HighestEquivalentTotalValue,
                    },
                  },
                  pricesMinMax: {
                    value: {
                      totalMin:
                        data.search.Availabilities?.TotalValueInformation
                          ?.LowestEquivalentTotalValue,
                      totalMax:
                        data.search.Availabilities?.TotalValueInformation
                          ?.HighestEquivalentTotalValue,
                    },
                  },
                },
              },
            });
          } else {
            dispatch({
              type: ActionTypes.Filters,
              payload: {
                filters: {
                  ...state.filters,
                  tariff_type: {
                    value: syncFranchise(carList),
                  },
                  prices: {
                    ...state.filters.prices,
                    value: {
                      min: data.search.Availabilities?.TotalValueInformation
                        ?.LowestTotalValue,
                      max: data.search.Availabilities?.TotalValueInformation
                        ?.HighestTotalValue,
                    },
                  },
                  pricesMinMax: {
                    value: {
                      totalMin:
                        data.search.Availabilities?.TotalValueInformation
                          ?.LowestTotalValue,
                      totalMax:
                        data.search.Availabilities?.TotalValueInformation
                          ?.HighestTotalValue,
                    },
                  },
                },
              },
            });
          }
        }

        if (data && data.search && !data.search.success) {
          dispatch({
            type: ActionTypes.SearchLoading,
            payload: {
              loading: false,
              search: payload,
            },
          });
          throw new Error(`Error on search: ${data.search.Error}`);
        }
      } catch (error) {
        //reportErrorToNewRelic(error);
        console.log('error scx', error);
      } finally {
        dispatch({
          type: ActionTypes.Loading,
          payload: {
            loading: false,
          },
        });
      }
    },
    [state.filters, currency, login, password],
  );

  const getResults = useCallback(() => {
    const results = applyFilters(state.fullList, state.filters, currency);
    const stores = transformToStoreList(results);
    const quantity = results.length;
    const pages = Math.ceil(quantity / PER_PAGE);
    const page = paginate(results, state.filters.page, PER_PAGE);

    return {
      quantity,
      pages,
      data: page,
      stores: stores,
    };
  }, [state]);

  const setLoading = useCallback((isLoading: boolean) => {
    dispatch({
      type: ActionTypes.SelectCarApiLoading,
      payload: { selectCarApiLoading: isLoading },
    });
  }, []);

  const getRentalToken = (car: MobilityCarProps) => {
    const rentalToken =
      state?.originalData?.TokenSession?.filter((item) =>
        item.includes(car.CarRentalCompanyName.toLowerCase()),
      )
        .at(0)
        ?.at(0) || '';

    return rentalToken;
  };

  const confirmCarAndGoToCheckout = useCallback(
    async (car: MobilityCarProps) => {
      setSelectCar(car);
      if (car) {
        try {
          setLoading(true);

          const data = await selectCar(
            car,
            state.tokens.authorization,
            getRentalToken(car),
            //state.tokens.token,
          );

          if (data && data.success) {
            if (localStorage) {
              localStorage.setItem(
                Tokens.MOBILITY_AUTH,
                JSON.stringify(state.tokens),
              );
              localStorage.setItem(
                Tokens.MOBILITY_SELECTED_CAR,
                JSON.stringify({
                  ...data,
                  searchData: state.search,
                  numberOfDailies,
                }),
              );
            }
          }

          const checkoutUrl = '/checkout';
          if (!isLogged) {
            openLoginModal(true, checkoutUrl);
            return;
          }
          router.push(checkoutUrl);
        } catch (error) {
          //reportErrorToNewRelic(error);
          dispatch({
            type: ActionTypes.CheckoutError,
            payload: {
              checkoutError: true,
            },
          });
          throw new Error('Error on select car');
        } finally {
          setLoading(false);
        }
      }
    },
    [state, router, isLogged, openLoginModal],
  );

  const setSelectCar = useCallback((car: MobilityCarProps | null) => {
    dispatch({
      type: ActionTypes.SelectCar,
      payload: {
        car,
      },
    });
  }, []);

  const setPage = useCallback(
    (value = 1) => {
      dispatch({
        type: ActionTypes.Filters,
        payload: {
          filters: {
            ...state.filters,
            page: value,
          },
        },
      });
    },
    [state],
  );

  const setFilter = useCallback(
    (field: FilterFields, filter: FilterFieldsValue) => {
      dispatch({
        type: ActionTypes.Filters,
        payload: {
          filters: {
            ...state.filters,
            page: 1,
            [field]: {
              ...state.filters[field],
              value: filter.value,
            },
          },
        },
      });
    },
    [state],
  );

  const toggleFilter = useCallback(
    (field: ToggleFilterFields, value: string) => {
      let newValue = [...(state.filters[field].value || []), value];

      if (state.filters[field].value?.includes(value)) {
        newValue = removeItemFromArray(state.filters[field].value || [], value);
      }

      if (field === 'categories' && value === 'all') {
        newValue = [];
      }

      dispatch({
        type: ActionTypes.Filters,
        payload: {
          filters: {
            ...state.filters,
            [field]: {
              ...state.filters[field],
              value: newValue,
            },
          },
        },
      });
    },
    [state],
  );

  const toggleFilterNumbers = useCallback(
    (field: ToggleFilterNumbersFields, value: number) => {
      let newValue = [...(state.filters[field].value || []), value];

      if (state.filters[field].value?.includes(value)) {
        newValue = removeItemFromArray(state.filters[field].value || [], value);
      }

      dispatch({
        type: ActionTypes.Filters,
        payload: {
          filters: {
            ...state.filters,
            page: 1,
            [field]: {
              ...state.filters[field],
              value: newValue,
            },
          },
        },
      });
    },
    [state],
  );

  const setCollapseFilter = useCallback(
    (field: CollapseFilters) => {
      dispatch({
        type: ActionTypes.Collapse,
        payload: {
          filters: {
            ...state.filters,
            [field]: {
              ...state.filters[field],
              open: !state.filters[field].open,
            },
          },
        },
      });
    },
    [state],
  );

  const openSidebar = useCallback((open: boolean) => {
    dispatch({
      type: ActionTypes.SidebarOpen,
      payload: {
        sidebarOpen: open,
      },
    });
  }, []);

  const removeAllFilters = useCallback(() => {
    dispatch({
      type: ActionTypes.Filters,
      payload: {
        filters: filtersInitialState.filters,
      },
    });
  }, []);

  useLayoutEffect(() => {
    clearCheckoutData();
  }, []);

  useEffect(() => {
    let updatedFilter;
    const checkedFranchise = syncFranchise(fullList);
    const isBRLCurrency = currency === 'BRL';

    if (isBRLCurrency) {
      updatedFilter = {
        ...state.filters,
        tariff_type: {
          value: checkedFranchise,
        },
        prices: {
          ...state.filters.prices,
          value: {
            min: data?.Availabilities?.TotalValueInformation
              ?.LowestEquivalentTotalValue,
            max: data?.Availabilities?.TotalValueInformation
              ?.HighestEquivalentTotalValue,
          },
        },
        pricesMinMax: {
          value: {
            totalMin:
              data?.Availabilities?.TotalValueInformation
                ?.LowestEquivalentTotalValue,
            totalMax:
              data?.Availabilities?.TotalValueInformation
                ?.HighestEquivalentTotalValue,
          },
        },
      };
    } else {
      updatedFilter = {
        ...state.filters,
        tariff_type: {
          value: checkedFranchise,
        },
        prices: {
          ...state.filters.prices,
          value: {
            min: data?.Availabilities?.TotalValueInformation?.LowestTotalValue,
            max: data?.Availabilities?.TotalValueInformation?.HighestTotalValue,
          },
        },
        pricesMinMax: {
          value: {
            totalMin:
              data?.Availabilities?.TotalValueInformation?.LowestTotalValue,
            totalMax:
              data?.Availabilities?.TotalValueInformation?.HighestTotalValue,
          },
        },
      };
    }

    dispatch({
      type: ActionTypes.Filters,
      payload: {
        filters: updatedFilter,
      },
    });
  }, [currency]);

  const setCheckoutError = useCallback((state: boolean) => {
    dispatch({
      type: ActionTypes.CheckoutError,
      payload: {
        checkoutError: state,
      },
    });
  }, []);

  useEffect(() => {
    if (state.originalData?.Availabilities?.LowestCarsGroups) {
      dispatch({
        type: ActionTypes.Search,
        payload: {
          loading: false,
          originalData: state.originalData,
          fullList: state.fullList,
          lowestCarsGroups: state.originalData.Availabilities.LowestCarsGroups,
          AvailabilityCarsGroups:
            state.originalData.Availabilities.AvailabilityCarsGroups,
          tokens: state.tokens,
        },
      });
    }
  }, [state.originalData]);

  const values = useMemo(
    () => ({
      ...state,
      results: getResults(),
      setPage,
      setFilter,
      setCollapseFilter,
      toggleFilter,
      checkoutError: state.checkoutError,
      toggleFilterNumbers,
      setSelectCar: setSelectCar,
      confirmCarAndGoToCheckout,
      fetchSearch,
      setLoading,
      openSidebar,
      setCheckoutError,
      removeAllFilters,
      totalValues,
      loadGoogleMaps,
    }),
    [
      state,
      getResults,
      setPage,
      setFilter,
      setCollapseFilter,
      toggleFilter,
      toggleFilterNumbers,
      setCheckoutError,
      setSelectCar,
      confirmCarAndGoToCheckout,
      fetchSearch,
      loadGoogleMaps,
      openSidebar,
      setLoading,
      removeAllFilters,
      totalValues,
    ],
  );

  return (
    <SearchContext.Provider value={values}>{children}</SearchContext.Provider>
  );
};

export function useSearch() {
  const context = useContext(SearchContext);

  if (!context) {
    throw new Error('useSearch must be used within a SearchContext');
  }
  return context;
}
